import React, { useEffect, useRef } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { globalToastItemsAtom, toastTypes } from '../../store/atoms/toasts';

export default function Toasts() {
  const timeoutsStartedRefs = useRef([]);
  const toastItems = useRecoilValue(globalToastItemsAtom);

  // Remove toast from the store. useRecoilCallback is necessary to
  // safely get and set toasts. If using 'actions', the state is unstable.
  const removeToast = useRecoilCallback(({ snapshot, set }) => async (id) => {
    let toasts = await snapshot.getPromise(globalToastItemsAtom);
    toasts = Array.prototype.slice.call(toasts);
    toasts = toasts.filter((toast) => toast.id !== id);
    set(globalToastItemsAtom, toasts);
  }, []);

  useEffect(() => {
    // Toast items have changed. Walk through every toast and
    // check if a timeout has already started.
    toastItems.forEach((toastItem) => {
      if (timeoutsStartedRefs.current.indexOf(toastItem.id) < 0) {
        timeoutsStartedRefs.current.push(toastItem.id);

        // Create a timeout that will remove the toast from the store.
        setTimeout(() => {
          removeToast(toastItem.id);
        }, toastItem.duration);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastItems]);

  if (!toastItems.length) {
    return null;
  }

  const listItems = toastItems.map((toastItem) => {
    let colors = '';

    if (toastItem.type === toastTypes.SUCCESS) {
      colors = 'bg-green-600 text-white';
    }

    if (toastItem.type === toastTypes.ERROR) {
      colors = 'bg-red-600 text-white';
    }

    if (toastItem.type === toastTypes.WARNING) {
      colors = 'bg-orange-500 text-white';
    }

    return (
      <li className={`px-5 py-4 md:px-6 md:py-5 ${colors}`} key={toastItem.id}>
        <button className="text-left" type="button" onClick={() => removeToast(toastItem.id)}>
          {toastItem.message}
        </button>
      </li>
    );
  });

  return (
    <div className="fixed top-20 md:top-auto md:bottom-6 lg:bottom-10 z-50 left-1/2 transform -translate-x-1/2 bg-white shadow-md rounded-md overflow-hidden max-w-xs md:text-lg md:max-w-md">
      <ul>
        {listItems}
      </ul>
    </div>
  );
}
