import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback } from 'react';
import { useDebouncedCallback } from '@react-hookz/web';
import useApi from '../../hooks/useApi';
import {
  scheduleActiveTaskId,
  scheduleActiveVehicleId,
  scheduleAlternativeAtom,
  scheduleChangeWarningShownAtom,
  schedulesDetailItemAtom,
  scheduleSelectedTasksAtom,
  scheduleSelectedVehiclesAtom,
  scheduleTasksAtom,
  scheduleTasksSearchAtom,
  scheduleVehiclesAtom,
  scheduleVehiclesSearchAtom,
} from '../atoms/scheduleDetail';
import useToastActions from './toasts';
import { apiErrorsFamily, apiLoadingFamily } from '../atoms/api';
import { scheduleDetailTasksListSelector, scheduleDetailVehiclesListSelector } from '../selectors/schedules';
import { translationsAtom } from '../atoms/i18n';
import useRouteErrors from '../../hooks/useRouteErrors';
import { expandedScheduleVehiclesAtom } from '../atoms/scheduleVehicles';

export default function useScheduleDetailActions() {
  const t = useRecoilValue(translationsAtom);

  // Schedule detail
  const [scheduleDetailItem, setSchedulesDetailItem] = useRecoilState(schedulesDetailItemAtom);
  const setScheduleDetailItemLoadingState = useSetRecoilState(apiLoadingFamily('schedule-detail'));
  const setScheduleAlternative = useSetRecoilState(scheduleAlternativeAtom);

  // Schedule detail tasks
  const setScheduleTasks = useSetRecoilState(scheduleTasksAtom);
  const setScheduleTasksLoadingState = useSetRecoilState(apiLoadingFamily('schedule-tasks'));
  const setActiveTaskId = useSetRecoilState(scheduleActiveTaskId);

  // Schedule detail vehicles
  const setScheduleVehicles = useSetRecoilState(scheduleVehiclesAtom);
  const setScheduleVehiclesLoadingState = useSetRecoilState(apiLoadingFamily('schedule-vehicles'));
  const setActiveVehicleId = useSetRecoilState(scheduleActiveVehicleId);
  const setScheduleVehicleTimeslotCreateLoadingState = useSetRecoilState(apiLoadingFamily('schedule-vehicles-timeslot-create'));
  const setScheduleVehicleTimeslotDeleteLoadingState = useSetRecoilState(apiLoadingFamily('schedule-vehicles-timeslot-delete'));

  const setScheduleRouteLoadingState = useSetRecoilState(apiLoadingFamily('schedule-route'));
  const setScheduleRouteErrors = useSetRecoilState(apiErrorsFamily('schedule-route'));
  const setScheduleSortLoadingState = useSetRecoilState(apiLoadingFamily('schedule-sort'));

  const setScheduleNotificationInformationLoadingState = useSetRecoilState(apiLoadingFamily('schedule-notification-information'));

  // ScheduleVehicles expanded
  const [
    expandedScheduleVehicles,
    setExpandedScheduleVehicles,
  ] = useRecoilState(expandedScheduleVehiclesAtom);

  const [
    scheduleChangeWarningShown,
    setScheduleChangeWarningShown,
  ] = useRecoilState(scheduleChangeWarningShownAtom);

  // Recoil selectors
  const scheduleDetailTasksList = useRecoilValue(scheduleDetailTasksListSelector);
  const scheduleDetailVehiclesList = useRecoilValue(scheduleDetailVehiclesListSelector);

  // Schedule detail selected states
  const [
    scheduleSelectedVehicles,
    setScheduleSelectedVehicles,
  ] = useRecoilState(scheduleSelectedVehiclesAtom);
  const [
    scheduleSelectedTasks,
    setScheduleSelectedTasks,
  ] = useRecoilState(scheduleSelectedTasksAtom);

  // Schedule detail search states
  const setScheduleVehiclesSearch = useSetRecoilState(scheduleVehiclesSearchAtom);
  const setScheduleVisitsSearch = useSetRecoilState(scheduleTasksSearchAtom);

  const toastActions = useToastActions();
  const routeError = useRouteErrors();

  const { handle: scheduleApiHandler } = useApi({
    identifier: 'schedule',
    onLoadingStateChange: (loadingState) => setScheduleDetailItemLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: scheduleUpdateApiHandler } = useApi({
    identifier: 'schedule-update',
    onLoadingStateChange: (loadingState) => setScheduleDetailItemLoadingState(loadingState),
    onError: (err) => {
      if (err.response?.data?.identifier === 'SCHEDULE_IN_PAST') {
        // eslint-disable-next-line no-param-reassign
        err.message = t.schedule_detail.schedule_in_past;
      }

      toastActions.addErrorFromObject(err);
    },
  });

  const { handle: scheduleDestroyApiHandler } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: scheduleVisitsApiHandler } = useApi({
    onLoadingStateChange: (loadingState) => setScheduleTasksLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: scheduleVehiclesApiHandler } = useApi({
    onLoadingStateChange: (loadingState) => setScheduleVehiclesLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: scheduleTitleUpdateApiHandler } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: scheduleRouteApiHandler } = useApi({
    onError: (err) => {
      toastActions.addErrorFromObject(err);
      setScheduleRouteLoadingState(false);
    },
    onLoadingStateChange: (loadingState) => setScheduleRouteLoadingState(loadingState),
  });

  const { handle: scheduleSortApiHandler } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setScheduleSortLoadingState(loadingState),
  });

  const { handle: scheduleSettingUpdateApiHandler } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: scheduleNotificationInformationApiHandler } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => {
      setScheduleNotificationInformationLoadingState(loadingState);
    },
  });

  const { handle: scheduleVehicleTimeslotCreateApiHandler } = useApi({
    onError: (err) => {
      if (err.response?.data?.message === 'Time slots overlap') {
        toastActions.addGlobalToastItem(t.schedule_detail.error_messages.timeslots_overlap, 'error', 6000);
        return;
      }

      toastActions.addErrorFromObject(err);
    },
    onLoadingStateChange:
      (loadingState) => setScheduleVehicleTimeslotCreateLoadingState(loadingState),
  });

  const { handle: scheduleVehicleTimeslotDeleteApiHandler } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange:
      (loadingState) => setScheduleVehicleTimeslotDeleteLoadingState(loadingState),
  });

  const canUpdateVehicles = useCallback(() => {
    if (scheduleDetailItem.notifications_sent_at && !scheduleChangeWarningShown) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(t.schedule_detail.sure_to_update_vehicles_after_notifications)) {
        return false;
      }

      setScheduleChangeWarningShown(true);
    }

    return true;
  }, [t, scheduleDetailItem, scheduleChangeWarningShown, setScheduleChangeWarningShown]);

  const canUpdateTasks = useCallback(() => {
    if (scheduleDetailItem.notifications_sent_at && !scheduleChangeWarningShown) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(t.schedule_detail.sure_to_update_tasks_after_notifications)) {
        return false;
      }

      setScheduleChangeWarningShown(true);
    }

    return true;
  }, [t, scheduleDetailItem, scheduleChangeWarningShown, setScheduleChangeWarningShown]);

  /**
   * Fetch a single schedule
   *
   * @param {Number} id
   * @returns Promise<any|null>
   */
  async function fetch(id) {
    const result = await scheduleApiHandler('get', `schedules/${id}`);

    if (!result) {
      return null;
    }

    if (typeof result.schedule_vehicles !== 'undefined') {
      const selectedVehicles = result.schedule_vehicles
        .map((scheduleVehicle) => scheduleVehicle.vehicle_id);

      // Only if the array has really changed, overwrite the selected vehicles.
      // This is to prevent the component from infinite re-renders. The array is always
      // seen as new by React.
      if (JSON.stringify(selectedVehicles) !== JSON.stringify(scheduleSelectedVehicles)) {
        setScheduleSelectedVehicles(selectedVehicles);
      }
    }

    if (typeof result.schedule_visits !== 'undefined') {
      const selectedTasks = result.schedule_visits
        .filter((scheduleVisit) => scheduleVisit.task_id)
        .map((scheduleVisit) => scheduleVisit.task_id);

      // Only if the array has really changed, overwrite the selected tasks.
      // This is to prevent the component from infinite re-renders. The array is always
      // seen as new by React.
      if (JSON.stringify(selectedTasks) !== JSON.stringify(scheduleSelectedTasks)) {
        setScheduleSelectedTasks(selectedTasks);
      }
    }

    setSchedulesDetailItem(result);

    return result;
  }

  async function fetchNotificationInformation(scheduleUuid) {
    const result = await scheduleNotificationInformationApiHandler('get', `schedules/${scheduleUuid}/notification-information`);

    if (!result) {
      return null;
    }

    return result;
  }

  /**
   * Fetch all tasks for a schedule
   *
   * @param {String} scheduleUuid
   * @returns Promise<any|null>
   */
  async function fetchTasks(scheduleUuid) {
    const results = await scheduleVisitsApiHandler('get', 'tasks-for-schedule', {
      schedule_uuid: scheduleUuid,
    });

    if (results) {
      setScheduleTasks(results);
    }

    return results;
  }

  /**
   * Fetch all vehicles for a schedule
   *
   * @param {String} scheduleUuid
   * @returns Promise<any|null>
   */
  async function fetchVehicles(scheduleUuid) {
    const results = await scheduleVehiclesApiHandler('get', 'vehicles-for-schedule', {
      schedule_uuid: scheduleUuid,
    });

    if (results) {
      setScheduleVehicles(results);
    }

    return results;
  }

  /**
   * Update schedule
   *
   * @param {String} scheduleUuid
   * @param {Object} scheduleData
   * @returns Promise<any|null>
   */
  async function update(scheduleUuid, scheduleVehicles, scheduleTasks) {
    const data = {};

    data.schedule_vehicles = scheduleVehicles
      .filter((id) => id)
      .map((id) => {
        return { id };
      });

    // TODO: Fix naming, these are not visits, but task ID's
    data.schedule_visits = scheduleTasks
      .filter((id) => id)
      .map((id) => {
        return { id };
      });

    const results = await scheduleUpdateApiHandler('put', `schedules/${scheduleUuid}`, data);

    fetch(scheduleUuid);

    return results;
  }

  /**
   * Destroy a schedule
   *
   * @param {String} scheduleUuid
   * @returns Promise<any|null>
   */
  async function destroy(scheduleUuid) {
    const result = await scheduleDestroyApiHandler('delete', `schedules/${scheduleUuid}`);

    return result;
  }

  /**
   * Calculate route for schedule
   *
   * @param {String} scheduleUuid
   * @param {String} strategy `optimal` or `keep_order`
   * @param {Object} options Additional parameters for routing
   * @returns Promise<any|null>
   */
  async function route(scheduleUuid, strategy, options = {}) {
    setScheduleRouteErrors([]);

    const results = await scheduleRouteApiHandler('post', `schedule-route/${scheduleUuid}`, {
      strategy,
      ...options,
    });

    if (results?.error) {
      toastActions.addGlobalToastItem(routeError.format(results.error), 'error');

      return results.error;
    }

    if (results?.errors && Array.isArray(results.errors)) {
      setScheduleRouteErrors(results.errors);
    }

    setScheduleAlternative(results?.schedule_alternative);

    return results;
  }

  /**
   * Update title for a schedule
   *
   * @param {String} scheduleUuid
   * @param {String} title
   * @returns Promise<any|null>
   */
  async function storeTitle(scheduleUuid, title) {
    const results = await scheduleTitleUpdateApiHandler('put', `schedule-title/${scheduleUuid}`, {
      description: title,
    });

    fetch(scheduleUuid);

    return results;
  }

  /**
   * Update sort of schedule_visits.
   *
   * @param {String} scheduleUuid
   * @param {Number[]} visitIds
   * @param {Object|null} scheduleVehicle
   * @param {Boolean} forceFetch
   * @returns Promise<any|null>
   */
  async function sortScheduleVisits(
    scheduleUuid,
    visitIds,
    scheduleVehicle = null,
    forceFetch = false,
  ) {
    try {
      await scheduleSortApiHandler('post', `schedule-sort/${scheduleUuid}`, {
        schedule_visits: visitIds,
        schedule_vehicle_id: scheduleVehicle?.id,
      });
    } catch (error) {
      await fetch(scheduleUuid);
      return;
    }

    if (forceFetch) {
      await fetch(scheduleUuid);
    }
  }

  async function updateVehicles(scheduleUuid, scheduleVehicles) {
    const data = {
      vehicles: scheduleVehicles
        .filter((id) => id)
        .map((id) => {
          return { id };
        }),
    };
    await scheduleUpdateApiHandler('put', `schedules/${scheduleUuid}/vehicles`, data);

    await fetch(scheduleUuid);
  }

  const updateVehiclesProxy = useDebouncedCallback((scheduleUuid, scheduleVehicles) => {
    updateVehicles(scheduleUuid, scheduleVehicles);
  }, [updateVehicles], 700);

  function toggleVehicle(vehicleId, include, updateDirectly = false, overrideVehicleCount = null) {
    if (!canUpdateVehicles()) {
      return;
    }

    const id = parseInt(vehicleId, 10);
    const maxVehicles = overrideVehicleCount || scheduleDetailItem.max_vehicles;

    // If the selected vehicles already matches the maximum number
    // of vehicles for the subscription, we cannot add another one.
    if (include && (scheduleDetailItem.daily_schedule_vehicles_count) >= maxVehicles) {
      // eslint-disable-next-line no-alert
      alert(t.schedule_detail.vehicle_limit_alert);
      return;
    }

    let newScheduleVehicles = [...scheduleSelectedVehicles];

    // Add the vehicle to the array
    if (include) {
      newScheduleVehicles.push(id);

    // Remove vehicle from the array
    } else {
      newScheduleVehicles = scheduleSelectedVehicles.filter((selectedVehicleId) => {
        return selectedVehicleId !== id;
      });
    }

    setScheduleSelectedVehicles(newScheduleVehicles);

    if (updateDirectly) {
      // In some cases, this function is called in a component that is about to unmount.
      // In that case, we need to update directly,
      // because the debounced function will not be executed.
      updateVehicles(scheduleDetailItem.uuid, newScheduleVehicles);
    } else {
      // Update the schedule in the database. We pass in our new vehicles array
      // directly, because the Recoil state might not be updated (yet) after
      // our set above.
      updateVehiclesProxy(scheduleDetailItem.uuid, newScheduleVehicles);
    }
  }

  /**
   * Select all vehicles
   *
   * @param {Boolean} checked
   * @returns void
   */
  function selectAllVehicles(checked) {
    if (!canUpdateVehicles()) {
      return;
    }

    if (!checked) {
      setScheduleSelectedVehicles([]);
      updateVehicles(scheduleDetailItem.uuid, []);
      return;
    }

    const maxVehicles = scheduleDetailItem.max_vehicles;

    if (new Set(scheduleSelectedVehicles).size >= maxVehicles) {
      return;
    }

    const selected = [...scheduleSelectedVehicles];
    const additionalVehicles = scheduleDetailVehiclesList
      .map((vehicle) => vehicle.id)
      .filter((id) => !selected.includes(id))
      .slice(0, maxVehicles - new Set(selected).size);

    const selectedVehicles = selected.concat(additionalVehicles);
    setScheduleSelectedVehicles(selectedVehicles);

    // Update the schedule in the database. We pass in our new vehicles array
    // directly, because the Recoil state might not be updated (yet) after
    // our set above.
    updateVehicles(scheduleDetailItem.uuid, selectedVehicles);
  }

  async function updateTasks(scheduleUuid, scheduleTasks) {
    const data = {
      tasks: scheduleTasks
        .filter((id) => id)
        .map((id) => {
          return { id };
        }),
    };

    await scheduleUpdateApiHandler('put', `schedules/${scheduleUuid}/tasks`, data);

    await fetch(scheduleUuid);
  }

  const updateTasksProxy = useDebouncedCallback((scheduleUuid, scheduleTasks) => {
    updateTasks(scheduleUuid, scheduleTasks);
  }, [updateTasks], 700);

  function toggleTask(taskId, include, updateDirectly = false) {
    if (!canUpdateTasks()) {
      return;
    }

    const ids = Array.isArray(taskId)
      ? taskId.map((id) => parseInt(id, 10))
      : [parseInt(taskId, 10)];

    const maxTasks = scheduleDetailItem.max_tasks;

    // If the selected tasks already matches the maximum number
    // of tasks for the subscription, we cannot add another one.
    if (include && maxTasks !== -1 && scheduleSelectedTasks.length >= maxTasks) {
      return;
    }

    let newSelectedTasks = [...scheduleSelectedTasks];

    // Add task to the array
    if (include) {
      newSelectedTasks = [...newSelectedTasks, ...ids];

    // Remove id from the tasks array
    } else {
      newSelectedTasks = scheduleSelectedTasks.filter((selectedTaskId) => {
        return ids.indexOf(selectedTaskId) < 0;
      });
    }

    setScheduleSelectedTasks(newSelectedTasks);

    if (updateDirectly) {
      // In some cases, this function is called in a component that is about to unmount.
      // In that case, we need to update directly,
      // because the debounced function will not be executed.
      updateTasks(scheduleDetailItem.uuid, newSelectedTasks);
    } else {
      // Update the schedule in the database. We pass in our new tasks array
      // directly, because the Recoil state might not be updated (yet) after
      // our set above.
      updateTasksProxy(scheduleDetailItem.uuid, newSelectedTasks);
    }
  }

  /**
   * Select all tasks
   *
   * @param {Boolean} checked
   * @returns void
   */
  function selectAllTasks(checked) {
    if (!canUpdateTasks()) {
      return;
    }

    if (!checked) {
      setScheduleSelectedTasks([]);
      updateTasks(scheduleDetailItem.uuid, []);
      return;
    }

    const maxTasks = scheduleDetailItem.max_tasks;
    let selectedTasks = scheduleDetailTasksList.map((task) => task.id);

    if (maxTasks && maxTasks > 0) {
      selectedTasks = selectedTasks.slice(0, maxTasks);
    }

    setScheduleSelectedTasks(selectedTasks);

    // Update the schedule in the database. We pass in our new tasks array
    // directly, because the Recoil state might not be updated (yet) after
    // our set above.
    updateTasks(scheduleDetailItem.uuid, selectedTasks);
  }

  /**
   * Deselect tasks
   *
   * @param {Array} taskIds
   * @returns void
   */
  function deselectTasks(taskIds) {
    if (!canUpdateTasks()) {
      return;
    }

    const tasksToDeselect = taskIds;

    const selectedTasks = scheduleSelectedTasks.filter((selectedTaskId) => {
      return !tasksToDeselect.includes(selectedTaskId);
    });

    // Update the schedule in the database. We pass in our new tasks array
    // directly, because the Recoil state might not be updated (yet) after
    // our set above.
    update(scheduleDetailItem.uuid, scheduleSelectedVehicles, selectedTasks);
  }

  /**
   * Search tasks
   *
   * @param {String} searchQuery
   * @returns void
   */
  function searchTasks(searchQuery) {
    setScheduleVisitsSearch(searchQuery);
  }

  /**
   * Search vehicles
   *
   * @param {String} searchQuery
   * @returns void
   */
  function searchVehicles(searchQuery) {
    setScheduleVehiclesSearch(searchQuery);
  }

  async function updateScheduleSetting(scheduleUuid, settingKey, settingValue) {
    const result = await scheduleSettingUpdateApiHandler('put', `schedule-setting-update/${scheduleUuid}`, {
      [settingKey]: settingValue,
    });

    fetch(scheduleUuid);

    return result;
  }

  async function lockAllVehicles(scheduleUuid, lock) {
    const results = await scheduleUpdateApiHandler(
      'put',
      `schedules/${scheduleUuid}/lock-all-vehicles`,
      { lock },
    );

    fetch(scheduleUuid);

    return results;
  }

  function resetScheduleState() {
    setSchedulesDetailItem(null);

    // Reset loaded tasks and vehicles to be selected
    setScheduleTasks([]);
    setScheduleVehicles([]);

    // Reset route errors
    setScheduleRouteErrors([]);

    // Reset potential alternative route
    setScheduleAlternative(null);

    // Reset search fields
    setScheduleVehiclesSearch('');
    setScheduleVisitsSearch('');
  }

  function addExpandedScheduleVehicle(scheduleVehicleId) {
    // Add if not already in the array
    if (!expandedScheduleVehicles.includes(scheduleVehicleId)) {
      setExpandedScheduleVehicles((prev) => {
        return [...prev, scheduleVehicleId];
      });
    }
  }

  function removeExpandedScheduleVehicle(scheduleVehicleId) {
    setExpandedScheduleVehicles((prev) => {
      return prev.filter((id) => id !== scheduleVehicleId);
    });
  }

  async function createTimeslot(scheduleVehicleId, scheduleUuid, data) {
    const result = await scheduleVehicleTimeslotCreateApiHandler('post', `schedule-vehicle-timeslots/${scheduleVehicleId}`, data);

    fetch(scheduleUuid);

    return result;
  }

  async function deleteTimeslot(scheduleVehicleId, scheduleUuid) {
    await scheduleVehicleTimeslotDeleteApiHandler('delete', `schedule-vehicle-timeslots/${scheduleVehicleId}`, []);

    fetch(scheduleUuid);
  }

  return {
    fetch,
    fetchTasks,
    fetchVehicles,
    fetchNotificationInformation,
    storeTitle,
    destroy,
    toggleVehicle,
    selectAllVehicles,
    toggleTask,
    selectAllTasks,
    deselectTasks,
    searchTasks,
    searchVehicles,
    update,
    route,
    sortScheduleVisits,
    updateScheduleSetting,
    resetScheduleState,
    setActiveVehicleId,
    setActiveTaskId,
    addExpandedScheduleVehicle,
    removeExpandedScheduleVehicle,
    lockAllVehicles,
    createTimeslot,
    deleteTimeslot,
  };
}
